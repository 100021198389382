
.DetailWrapper{
    height: 100%;
    padding: 10px;
    margin: 0 auto;
    overflow: auto;
    .detailsRow{
        background: var(--border-color);
        border-radius: 10px;
        margin: 0 auto;
        width: 70%;
    }
    .ImagePanel{
        padding: 10px;
        .satImages{
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            margin-bottom: 15px;
            border-radius: 10px;
        }
    }

    .location{
        font-size: 15px;
        color: var(--primary-color-light_alt);
    }
    .InfoContainer{
        padding: 20px;
        background: var(--border-color);
        border-radius: 10px;
        .fieldInfo{
            margin: 20px 0;
            
        }
        .info{
            padding: 7px;
        }
        
        .header{
           color:var(--secondary-color) ;
        }
        .info{
            text-transform: capitalize;
            font-size: 13px;
            .obj_label{
                font-weight: bold;
            }
        }
    }
}


.objectSection{
    padding: 20px;
    border-radius: 10px;
    margin: 10px 0;
    background: var(--background-primary);
    .objectSectionHeader{
        font-size: 14px;
        color: var(--secondary-color);
        letter-spacing: 1px;
        font-weight: normal;
    }
}
.underline{
    margin: 10px 0;
    color: var(--secondary-color);
}

.innerObjectSection{
    padding: 20px;
    border-radius: 10px;
    margin: 10px 0;
    background: #fff;
}
.badgeWrap{
    align-items: center;
    
}
.entityWidget{
    .headericon{
        margin-right: 10px;
        color: var(--primary-color-light_alt);
    }
}
.entitybadge{
    background: var(--background-secondary);
    padding: 7px 15px;
    color: var(--primary-color-light);
    border-radius: 25px;
    margin: 10px 5px 0;
    cursor: pointer;
    .entityIcon{
        margin-right: 10px;
    }
    &:hover{
        color: var(--primary-color-dark);
    }
}
.selectedEntityBadge{
    background: var(--primary-color-light);
    color: white;
}
.maskedContent{
    color: red !important;
}
.link{
    cursor: pointer;
    color: #3535e7;
    text-decoration: underline;
    &:hover{
        opacity: 0.8;
    }
}