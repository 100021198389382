@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100vh;
  font-family: "Poppins", sans-serif !important;
  --primary-color-light: #ffc107;
  --primary-color-light_alt: #6cbe03;
  --primary-color-dark: #221d18;
  --secondary-color: #8699ad;
  --background-primary: #fbfbfb;
  --background-secondary: #e2e8f7;
  --border-color: #eeeeee;
  --background-gradient: linear-gradient(180deg, #e2e8f7 0%, #fbfbfb 100%);
  --box-shadow: 0 3px 6px rgb(0 0 0 / 10%), 0 3px 6px rgb(0 0 0 / 15%);
}

::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background-color: #cacfde;
  border: 2px solid white;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background-color: var(--background-primary);
  box-shadow: none;
}
input:focus-visible{
  outline: none;
}


.text-center {
  text-align: center !important;
}
.flex {
  display: flex !important;
}
.inline {
  display: inline-block !important;
}
.maxHeight {
  height: 100% !important;
}
.maxWidth {
  width: 100% !important;
}
.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}
.noMargin{
  margin: 0 !important;
}
.noPadding{
  padding: 0 !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.disabledPanel{
  opacity: 0.6;
  pointer-events: none;
}
.pullRight{
  float: right !important;
}
.completed{
  color: #6ce399;
}
.processing, .awaiting{
  color: #f5b05b;
}
.failed{
  color: #ed7546;
}
.reviewing{
  color: #3d72e7;
}
.centerAlign{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centerAlignVertical{
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}
.overflowAuto{
  overflow: auto;
}

.greenBtn{
  background: var(--primary-color-light_alt) !important;
  border: 1px solid var(--primary-color-light_alt) !important;
}

.primaryBtnStyle{
  background: var(--primary-color-dark);
  color: #fff;
  cursor: pointer;
  padding: 8px 20px;
  border: 1px solid var(--primary-color-dark);
  font-size: 13px;
  letter-spacing: 1.5px;
  &:hover {
    opacity: 0.8;
  }
}
.secondaryBtnStyle {
  background: var(--primary-color-light);
  color: #fff;
  cursor: pointer;
  padding: 8px 20px;
  border: 1px solid var(--secondary-color);
  font-size: 12px;
  letter-spacing: 1.5px;
  &:hover {
    opacity: 0.8;
  }
}
.tertiaryBtnStyle {
  background: var(--background-gradient);
  color: var(--primary-color-dark);
  cursor: pointer;
  padding: 8px 20px;
  border: 1px solid var(--primary-color-dark);
  font-size: 12px;
  letter-spacing: 1.5px;
  &:hover {
    opacity: 0.8;
  }
}
.inlineBtnStyle{
  font-size: 12px;
  letter-spacing: 1.5;
  font-weight: 600;
  color: var(--primary-color-light);
  cursor: pointer;
  padding: 5px 10px;
  border: none;
  background: transparent;
  &:hover {
    opacity: 0.8;
  }
}

.pageWrap {
  .headerWrap {
    height: 40px;
    font-size: 17px;
    font-weight: 600;
    color: var(--primary-color-dark);
    padding: 0px 10px;
    align-items: end;
    justify-content: space-between;
    text-transform: capitalize;
    span{
      background: var(--border-color);
      color: var(--primary-color-light);
      margin: 0px 0px 5px 10px;
      padding: 1px 10px;
      font-size: 14px;
      border-radius: 15px;
    }
  }
  .subheaderWrap {
    height: 40px;
    color: var(--primary-color-dark);
    padding: 0px 10px;
    align-items: center;
    justify-content: space-between;
    .meta {
      font-size: 12px;
      color: var(--primary-color-light);
      margin: 0px 10px 0 0;
    }
  }
  .contentWrap {
    height: calc(100% - 80px);
    overflow: auto;
    .sectionHeader{
      font-size: 16px;
      color: var(--primary-color-light);
      padding: 0 10px;
      height: 40px;
      align-items: center;
      display: flex;
      .headerButton{
        cursor: pointer;
        color: var(--primary-color-dark);
        padding: 6px 15px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        // margin: 0 10px 0 0;
        &:hover{
          background: white;
          color: var(--primary-color-light);
        }
      }
      .selectedHeaderButton{
        background: linear-gradient(180deg, var(--primary-color-dark) 0%, var(--primary-color-light) 100%);
        color: #fff;
        &:hover{
          background: linear-gradient(180deg, var(--primary-color-dark) 0%, var(--primary-color-light) 100%);;
          color: #fff;
          opacity: 0.7;
        }
      }
    }
    .sectionSubheader{
      font-size: 14px;
      color: var(--secondary-color);
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      padding: 0 10px;
      height: 40px;
      align-items: center;
      display: flex;
    }
    .contentHolder {
      background: white;
      border: 1px solid var(--border-color);
      margin-right: 20px;
      border-radius: 5px;
      box-shadow: var(--box-shadow);
    }
    .modelNamesWrap{
      width: 12%;
      background: var(--background-primary);
    }
    .rightsideCol{
      width: 88%;
    }
    .sectionContent{
      height: calc(100% - 40px);
    }
    .modelDetailsWrap{
      width: 42%;
    }
    .modelSelectionDetailsWrap{
      width: 58%;
    }
    .steps-wrapper {
      height: calc(100% - 110px);
      overflow: auto;
    }
    .modelWrap{
      height: calc(100% - 70px);
      overflow: auto;
      border: 1px solid var(--border-color);
      background: white;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      box-shadow: var(--box-shadow);
      .name{
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1.5px;
        padding: 10px;
        .status{
          font-weight: normal;
          font-size: 11px;
        }
      }
    }
    .footerBtnWrap{
      height: 70px;
      align-items: center;
    }
    .causalBrowserWrap{
      overflow: hidden;
      height: calc(100% - 10px);
      width: calc(100% - 10px);
      border: 1px solid var(--border-color);
      background: white;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      box-shadow: var(--box-shadow);
    }
    .fullWidthBtnStyle{
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 15px 20px;
      background: var(--primary-color-dark);
      font-size: 14px;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      cursor: pointer;
      color: #fff;
      font-weight: 600;
      border:none;
      &:hover{
        opacity: 0.8;
      }
    }
  }
}

.tableWrap{
  height: calc(50% - 60px);
  overflow: auto;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.link {
  cursor: pointer;
}

.input-wrapper {
  color: var(--primary-color-dark);
  margin: 15px 0 !important;
  label {
    display: block;
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 13px;
  }
  input, textarea {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    &:active {
      outline: none;
      border: 1px solid var(--secondary-color);
    }
  }
  .dropdownInput{
    width: 100%;
    font-size: 13px;
    border: none;
    padding: 0 !important;
    &:active {
      outline: none;
      border: none;
    }
  }
  .dropdownMenu{
    font-size: 13px;
  }
}

.modalStyle {
  min-width: 600px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);
  .modalHeader {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-color-dark);
    padding: 0px 20px;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid var(--border-color);
  }
  .modalBody {
    height: calc(80% - 40px);
    overflow: auto;
    background: var(--background-primary);
    padding: 20px;
  }
  .modalFooter {
    height: 60px;
    background: var(--background-primary);
    align-items: center;
    justify-content: end;
    padding: 0 20px;
    button {
      margin: 0px 5px;
    }
  }
}

.noResults{
  text-align: center;
  font-size: 14px;
  color: var(--secondary-color);
  padding: 20px 0;
}

.grayBoxShadow{
  box-shadow: 0 3px 6px rgb(0 0 0 / 10%), 0 3px 6px rgb(0 0 0 / 15%);
  &:hover{
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.10);
  }
}