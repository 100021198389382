.accessWrapper{
    height: 60px;
    align-items: center;
    justify-content: center;
    .mapview{
    width: fit-content;
    padding: 10px 15px;
    border-radius: 25px;
    border: 1px solid var(--background-secondary);
    color: var(--primary-color-light);
    font-size: 17px;
    margin: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    }
}
.bodyWrapper{
    height: calc(100% - 60px);
    padding: 0 20px 10px;
}

.nlqPane{
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(45deg, var(--background-secondary), var(--background-primary));
    .vertical{
        flex-direction: column;
        width: 50%;
        max-height: 95%;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
    }
    .smartAssist{
        font-size: 25px;
        font-weight: normal;
        letter-spacing: 5px;
        color: var(--primary-color-light);
        padding-bottom: 20px;
    }
    .queryInputStyle{
        width: 100%;
        border-radius: 50px;
        padding: 30px 50px;
        font-size: 16px;
        border: 1px solid var(--primary-color-light);
        color: var(--primary-color-light);

    }
}

.aichatBody{
    width: 100%;
    height: calc(100% - 60px);
    overflow: auto;
    font-size: 14px;
    padding: 20px;
    .queryText{
        margin: 5px 0;
        max-width: 100%;
        padding: 7px;
        width: fit-content;
        border-radius: 10px;
    }
    .query{
        background: var(--background-secondary);
    }
    .reply{
        background: var(--primary-color-light);
    }
    .chatloading{
        right: 10px;
        bottom: 10px;
        background: white;
        padding: 5px 10px;
        font-size: 11px;
        color: darkgrey;
        border-radius: 5px;
    }
}
.loadingMsg{
    font-size: 12px;
    color: lightgrey;
    font-style: italic;
    padding: 5px 0;
    align-items: start;
}