.login-wrapper {
    // text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-size: 13px;
    background: var(--background-primary);
    .login-card {
        border-radius: 10px;
        // box-shadow: 10px 10px 8px #888;
        padding: 50px 40px;
        width: 100%;
        max-width: 600px;
        margin-bottom: 30px;
        background: white;
        .header-wrapper {
            text-align: center;
            margin-bottom: 20px;
            .logoWrapper{
                flex-direction: column;
                align-items: center;
                .header-logo {
                    width: 100%;
                    max-width: 200px;
                    margin-bottom: 10px;
                }
                .agristack-logo{
                    width: 50%;
                    max-width: 100px;
                    margin-bottom: 10px;
                }
            }
            .header-main {
                font-size: 1.8em;
                color: var(--primary-color-light);
            }
        }
        .login-form {
            color: var(--primary-color-dark);
            
            .login-button-wrapper {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .checkbox-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    line-height: 1;
                    label {
                        padding-bottom: 2px;
                    }
                }
            }
        }
        .forgot-password {
            margin-top: 50px;
            &:hover {
                opacity: 0.8;
            }
        }
    }
    .sign-up {
        margin-top: 30px;
        .link {
            color: #58a6ff;
            &:hover {
                // opacity: 0.8;
                text-decoration: underline;
            }
        }
    }
    .roleBadge{
        border-radius: 15px;
        padding: 2px 10px;
        cursor: pointer;
        background: gainsboro;
        margin-right: 10px;
        text-transform: capitalize;
        text-align: center;
    }
    .selectedBadge{
        background: var(--primary-color-light_alt);
        color:white;
    }
}
