
.gridOuterWrapper{
    background: var(--border-color);
    border-radius: 10px;
    padding: 25px;
}
.tableWrapper{
    width: 100%;
    .tableHeader{
        color: var(--primary-color-light_alt);
        font-weight: bolder;
        td{
            font-weight: bold !important;
        }
        // border-bottom: 1px solid var(--primary-color-light_alt);
    }
    tbody{
        tr{
            font-size: 12px;
            cursor: pointer;
            td{
                padding: 15px 10px;
            }
            &:hover{
                background: var(--background-primary);
                transition: all 0.4s ease;
            }
        }
    }
}

td,th{
    padding: 10px;
    // font-weight: normal;

}
