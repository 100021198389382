.level{
    width: fit-content;
    padding: 10px 15px;
    border-radius: 25px;
    background: var(--background-secondary);
    color: var(--primary-color-light);
    font-size: 14px;
    margin: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    text-transform: uppercase;
    .icon{
        font-size: 20px;
        margin-right: 10px;
        display: flex;
    }
}
.selectedLevel{
    background: var(--primary-color-light_alt);
    color: white;
}